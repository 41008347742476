import React from 'react'
import foto from './../assets/images/profile.svg';
import cali1 from './../assets/images/cali1.svg';
import cali2 from './../assets/images/cali2.svg';
import cali3 from './../assets/images/cali3.svg';
import { useTranslation } from 'react-i18next';

const Servicio   = () => {
    const [t, i18n]                    = useTranslation("global");
  return (
    <div>

            <div className='list_comen'>

                    <div className='titulo'>
                             <div className="text_main">{t('service')}</div>
                             <div className="text_main">{t('authent')}</div>
                             <div className="line_main"></div>
                    </div>
                    
                    <div className='leyenda'>
                             <div className="row">
                                    <div className='col-md-4 col-12 p-1'>
                                              
                                            <div className='dialog'>
                                                    <div className='text-center'>
                                                        <img src={foto} className="img_diag"/>
                                                    </div>

                                                    <div className='text'>
                                                         <div className="titulo">Elída Perez</div>
                                                         <div className="comentario">
                                                                   {t('testimonio1')}
                                                         </div>
                                                    </div>

                                                    <div className='text-center'>
                                                        <img src={cali1} className="img_fin"/>
                                                    </div>

                                            </div>
                                                

                                    </div>
                                    <div className='col-md-4 col-12'>

                                                <div className='dialog'>
                                                    <div className='text-center'>
                                                        <img src={foto} className="img_diag"/>
                                                    </div>

                                                    <div className='text'>
                                                         <div className="titulo">Gloria Batista</div>
                                                         <div className="comentario">
                                                               {t('testimonio2')}
                                                         </div>
                                                    </div>

                                                    <div className='text-center'>
                                                        <img src={cali3} className="img_fin"/>
                                                    </div>

                                                </div>
                                                
                                        

                                    </div>
                                    <div className='col-md-4 col-12'>

                                               <div className='dialog'>

                                                    <div className='text-center'>
                                                        <img src={foto} className="img_diag"/>
                                                    </div>

                                                    <div className='text'>
                                                         <div className="titulo">Juan Landaeta</div>
                                                         <div className="comentario">
                                                                {t('testimonio3')}
                                                         </div>
                                                    </div>

                                                    <div className='text-center'>
                                                        <img src={cali2} className="img_fin"/>
                                                    </div>
                                                </div>
                                                



                                    </div>
                             </div>
                                    
                           

                    </div>

            </div>

                <style>

                {`  
                           .list_comen .dialog .titulo{
                               font-size:18px;
                               font-weight:700;
                           }
                               .list_comen{
                                 padding:50px;
                                  padding-top:120px;
                               }

                           .img_fin{
                               margin-top:10px;
                                width:50%;
                           }

                           .list_comen .dialog{
                             padding:25px;
                             border-radius:24px;
                            background: rgba(203,236,218,0.2); 
                                  border-radius:8px;
                               
                           }
                           
                           .dialog .img_diag{
                               width:64px;
                               margin-bottom:30px;
                           }
                              

                              @media only screen and (max-width : 900px) {

                                        .mejor_cripto{
                                            padding:20px;
                                        }
                                            .leyenda .d-flex{
                                                margin-top:40px;
                                                margin-bottom:10px;
                                            }

                                    
    
                            }
                     `}  

                        
                

                </style>

    </div>
  )
}

export default Servicio