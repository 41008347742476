import React from 'react'
import { useTranslation } from 'react-i18next';
import logo_cambia from './../assets/images/icon3.webp';


const Cambia_cripto = () => {

    const [t, i18n]      = useTranslation("global");


  return (  <> 
              <div className="zona_cambio">
                      
                         <div className='row'>
                                <div className='col-md-6  col-12'>
                                      <img src={logo_cambia} className="img-fluid"/>

                                </div>
                                <div className='col-md-6 col-12 text-right the_ri'>

                                            <div className="text_main"> {t('changes')}</div>
                                            <div className="text_main">{t('yourcrytp')}</div>
                                            <div className="line_main  right_pu"></div>

                                            <div className="leyenda">
                                                     <p className="text-right">
                                                         {t('cambio_text')}
                                                     </p>
                                            </div>
                                </div>
                         </div>
                         <div className='row'>
                             <div className='col-md-4  col-12'>
                                         
                                        <div className='text_main text-center'><span className="verti">95%</span>{t('rates')}</div>  

                             </div>
                             <div className='col-md-4  col-12'>
                                       
                                    <div className='text_main text-center'>{t('customer')}</div>  
                           </div>
                           <div className='col-md-4  col-12'>
                                       
                                  <div className='text_main text-center'>{t('swap')}</div>  

                           </div>
                          
                      </div>
                      
                      <div className='text-center sepa'>
                           <button className="btn btn_orange text-bold "> {t('startnow')} </button>
                      </div>
                         
                </div>
                <style>

                        {`  

                              .zona_cambio{
                                  margin-top:40px;
                                  background: rgba(203,236,218,0.2); 
                                  border-radius:8px;
                                  padding:50px;
                                  border-radius:24px;
                                  margin-bottom:40px;
                              }

                              .sepa{
                                   margin-top:10px;
                               }

                              .the_ri{
                                    padding-left:50px;
                                    padding-top:100px;
                              }

                              .verti{
                                  font-size:12px;
                                  font-weight:500;
                                   text-orientation: upright;
                                    writing-mode: vertical-rl;
                                   color:#0f0;
                              }

                              .right_pu{
                                  float:right;
                                  margin-right:5px;
                              }
                        `}

                 </style>  


             </> 
  )
}
export default Cambia_cripto