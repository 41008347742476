import React from 'react'
import { useTranslation } from 'react-i18next';
import logo_cambia from './../assets/images/icon4.webp';
const Limite = () => {
    
    const [t, i18n]      = useTranslation("global");
  return (    <div>

             <div className="zona_limite">
                      
                      <div className='row'>

                             <div className='col-md-6  col-12 text-center'>
                                         <img src={logo_cambia} className="img-fluid img_li"/>
                             </div>

                             <div className='col-md-6  col-12 text-right the_ri'>
                                                
                                          <div className="text_main">{t('Limite_ti')}</div>
                                            <div className="text_main">{t('change_ti')}</div>
                                            <div className="line_main  right_pu"></div>

                                            <div className="leyenda">
                                                     <p className="text-right">
                                                        {t('limite_text')}
                                                     </p>
                                            </div>
                             </div>

                      </div>

                   {/*   <div className='text-center sepa'>
                           <button className="btn btn_orange text-bold "> {t('herecharge')} </button>
                      </div> */}
                      
             </div>


             <style>

                     {`  

                           .zona_limite{
                                 margin-top:80px;
                              background: rgba(203,236,218,0.2); 
                              border-radius:8px;
                                  padding:50px;
                                  border-radius:24px;
                           }

                           .img_li{
                               width:80%;
                           }




                     `}

              </style>  

  </div>
  )
}
export default Limite