import React, {useState} from "react"
import {Navigate, Link, useNavigate } from 'react-router-dom';
import { color } from './../../common/theme';
import axios  from "axios";
import './../../assets/css/auth.css'
/*import {useTranslation} from "react-i18next"; */


export default function Login(){

      const nav = useNavigate();
    //const [t, i18n] = useTranslation("global");
   // const Aunthe = useAuth(); 
   // const goto = useNavigate();
/*
    if(Aunthe.isAuthenticator){
           return <Navigate to="/dashboard" />
    }
           */

    const [email, setEmail]   = useState("");
   
    async  function solicitud(e){
                e.preventDefault();
                const data ={
                    email:email
                }
                
                nav= "/confirmpwd";

                try{
                        const response = await axios.post('http://localhost:8000/api/login', data );
                        const re =  response.data;
                        if(re.id){
                               console.log(re);
                         }else{
                               console.log(re);
                         }
                          
                }catch(err){
                         console.log(err)
                }

   }

   
    return    <>
                 
                 <div className="content-fluid">
                 <div className="row justify-content-center">
                 <div className="col-md-6 col-12 ">
                    
                    <div className='zona_login '>
                                 <div className="titulo">Recover your password</div>
                                <form className="arriba_menu clr_blue"  onSubmit={solicitud}>
                                 
                                            <div className='form-group mb-3'>
                                                    <label>Email</label>
                                                    <input type="text" className="form-control p-2" 
                                                     value = {email}
                                                     onChange={(e) => setEmail(e.target.value)}
                                                     id='email' name='email'/>
                                            </div>


                                            <div className='form-group mb-2'>
                                            <div class="d-grid gap-2">
                                                    <button className="btn btn_orange">Reset</button>
                                               </div>
                                            </div>


                                            <div className='form-group mb-2'>
                                        
                                            </div>
                                         
                                            <div className='footer'>
                                                   Do you have account <Link to="/">Sing In!</Link> 
                                          </div>
                             </form>
              

                    </div></div></div></div>

                    <style> 
                      {`
                                 .zona_login{
                                      margin-top: 17%;
                                     background:${color.GREEN_AUTH}
                                 }

                      `} 
                      </style>
               </>
  }