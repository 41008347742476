import React, { useState } from "react"
import {color} from "./../common/theme";
import { useTranslation } from "react-i18next";


const Comple_signup = () => {
    const [t, i18n]                                 = useTranslation("global");
    const [name, setName]                           = useState("");
    const [lastname, setLastname]                   = useState("");
    const [pass, setPass]                           = useState("");
 
    const [img_check, setimg_check]                 = useState("");
    const [errorResponse, seterrorResponse]         = useState("");

  /*  const Aunthe = useAuth();
    if(Aunthe.isAuthenticator){
           return <Navigate to="/dashboard" />
    } */

    const img_ina = "./img/check.svg";
    const img_act = "./img/check_a.svg";
    
    
    function validar_pasword(passw ){

        setPass(passw);
        seterrorResponse("");
        let contar = 0;
        const v1 = document.getElementById("vali_1");
        const v2 = document.getElementById("vali_2");
        const v3 = document.getElementById("vali_3");
        const v4 = document.getElementById("vali_4");
       
        if (passw.match(/[^a-zA-Z\d]/)) {
                v1.setAttribute("src", img_act);
                contar ++;
            }else{ v1.setAttribute("src", img_ina);}
         
        if (passw.match(/\d/)) {
                v2.setAttribute("src", img_act);
                contar ++;
            }else{ v2.setAttribute("src", img_ina);}
        
       if (passw.match(/[a-z]/) || passw.match(/[A-Z]/)) {
                v3.setAttribute("src", img_act);
             contar ++;
        }else{ v3.setAttribute("src", img_ina);}
       
        if (passw.length >= 8) {
               v4.setAttribute("src", img_act);
            contar ++;
        }else{  v4.setAttribute("src", img_ina);
        }
         
       
        return contar;
    }

    async function send_register(e){
        
                    seterrorResponse("");
                     e.preventDefault();
                     const data ={
                             name : name,
                             lastname: lastname,
                             password:pass,
                     }
                    
                     let contar = validar_pasword(pass);
                     if(contar < 4){
                           seterrorResponse("Invalid Password.");
                           return;
                     }else  setPass(pass);


                   /*
                     try{
                        const response = await axios.post(comple.server+comple.auth.register, data );
                      
                           const resp = response.data;
                           if(resp.id){
                                   Aunthe.SaveUser(response);
                           }else{
                                   seterrorResponse("Invalid fields.");
                           }
                     }catch(err){

                          console.log(err);
                           seterrorResponse("An error has occurred.");
                     }
                    */

    }
       
  return (
    <>
                  
                  <div className='zona_login'>

                        <h3 className="titulo">{t("complete_register")}</h3>

                                                            <div className='form-grou mb-2'>
                                                                    <label className='i_m'>{t("name")}</label>
                                                                    <input type="text" 
                                                                    className="form-control  p-2 " 
                                                                    value={name}
                                                                    onChange={(e) => setName(e.target.value)}
                                                                    required
                                                                    id='name' name='name'/>
                                                            </div>

                                                            

                                                            <div className='form-grou mb-2'>
                                                            <label className='i_m'>{t("lastname")}</label>
                                                                    <input type="email" 
                                                                            className="form-control p-2 "
                                                                            value={lastname} 
                                                                            required
                                                                            onChange={(e) => setLastname(e.target.value)}
                                                                    id='email' name='email'/>
                                                                    
                                                            </div>

                                                            <div className='form-group mb-2'>
                                                            <label className='i_m'>{t("password")}</label>
                                                                    <input type="password" 
                                                                            className="form-control  p-2 "
                                                                            value={pass} 
                                                                            aria-required
                                                                            onChange={(e) =>validar_pasword(e.target.value)}
                                                                    id='password' name='password'/>
                                                                    
                                                            </div>
                                                            <div className='mb-1 p-1 text-left small'><img src={img_ina} id="vali_1" className="img-fluid vali_1 icon-x1"/> {t("one_special")}</div>
                                                            <div className='mb-1 p-1 text-left small'><img src={img_ina} id="vali_2" className="img-fluid vali_2 icon-x1"/>  {t("one_number")}</div>
                                                            <div className='mb-1 p-1 text-left small'><img src={img_ina} id="vali_3" className="img-fluid vali_3 icon-x1"/>  {t("one_letter")}</div>
                                                            <div className='mb-3 p-1 text-left small'><img src={img_ina} id="vali_4" className="img-fluid vali_4 icon-x1"/>   {t("one_long")}</div>
                                                            
                                                            {!!errorResponse  && <div className="bg-danger text-white p-2 mb-2  rounded-pill">{errorResponse}</div>}

                                                            <div className='form'>
                                                            <div className="d-grid gap-2">
                                                                    <button className="btn btn_orange">{t("signup")}</button>
                                                                    </div>
                                                            </div>
                                 
                                        
                        </div>

    
    </>
  )
}

export default Comple_signup

