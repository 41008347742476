//let servidor = "http://localhost:8000/api";
const servidor = "https://server.appcoinsbx.com/api";

const rutas = {
    auth: { 
             login: servidor+'/login',
             register: servidor+'/register',
             complete: servidor+'/complete/codigo/complete/form',
    },
    tokens:             servidor+'/token',
    monedas:            servidor+'/divisas',
    rate:              servidor+'/rate',
    cal_exc:           servidor+'/calculo_exchange',
    genera_exchange:   servidor+'/genera_exchange',
    proceso_exchange:  servidor+'/proceso_exchange',
    profile:           servidor+'/profile',
    genera_buy:        servidor+'/genera_buy',
    proceso_bu:        servidor+'/proceso_buy',
    identity:          servidor+'/identity',
    transacciones:     servidor+'transacciones'

}

export default rutas;