import React from 'react'
import { useTranslation } from 'react-i18next';
import logo_cambia from './../assets/images/icon5.webp';
const Compra = () => {
    
    const [t, i18n]      = useTranslation("global");
  return (
    <div>
                      <div className="zona_compra">

                                  <div className="row">

                                            <div className='col-md-6 col-12 head_left pt-5'>
                                                        <div className="titulo_main mt-5">
                                                              <div className="text_main">{t("buy")}</div>
                                                              <div className="text_main">{t("yourcrytp")}</div>
                                                            <div className="line_main"></div>
                                                        </div>

                                                        <div className='text_resena'>
                                                              {t('compra_text')} 
                                                        </div>                  
                                            </div> 

                                            <div className='col-md-6 col-12'>
                                                         <img src={logo_cambia} className="img-fluid"/>        
                                            </div> 
                                    </div>

                                    <div className='text-center sepa'> 
                                            <button className="btn btn_orange text-bold "> {t('herebuy')} </button>
                                    </div>
                      </div>

                        <style>

                                {`  
                                          .zona_compra{
                                              margin-top:80px;
                                              background: rgba(203,236,218,0.2); 
                                              border-radius:8px;
                                              padding:50px;
                                              border-radius:24px;
                                          }

                                          .zona_compra .sepa{
                                          }

                                          .zona_comora .sepa img{
                                               margin-top:-100px;
                                          }

                                `}

                        </style>  

    </div>
  )
}

export default Compra