import React from 'react'
import Inter from './../complements/form_exchange'

const Exchange = () => {
  return ( <>
                  <div className='sec_exchange'>

                     <div className='row justify-content-center'>
                            <div className='col-md-6 col-12'>
                                 <Inter/>
                            </div>
                     </div>
                  </div>
                   

                   <style>
                  {`    
                              .sec_exchange{
                                     
                                          border-radius:8px;
                                          padding:20px;
                                          border-radius:24px;
                              }
                      
                              .zona_limite{
                              margin-top:80px;
                              background:#f3fbf3;
                              padding:50px;
                              border-radius:24px;
                        }

                       
      

                  `}
                  </style>
          </>
  )
}

export default Exchange