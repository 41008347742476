import { BrowserRouter, Routes, Route, } from 'react-router-dom'
import Loss             from './views/auth/losspwd';
import Login            from './views/auth/login';
import ProtectedRoute   from './views/ProtectedRoute';
import Register         from './views/auth/register';
import Fee              from './views/fee';
import About            from './views/about';
import How              from './views/howit';
import Main             from './views/main';
import Exchange         from './views/exchange';
import Sellbuy          from './views/sellbuy';
import {Authcontext}      from './provider/authcontext';
import Plantilla from './views/plantilla';



function App() {
  return (
            <Authcontext.Provider value={{is_auth:true}}>
                  <BrowserRouter>
                              <Routes>

                                          <Route path="/" element={<Plantilla> <Main/> </Plantilla> } />
                                          <Route path="/howitswork" element={<Plantilla>  <How/> </Plantilla> } />
                                          <Route path="/fee" element={<Plantilla>  <Fee/> </Plantilla> } />
                                          <Route path="/sellbuy" element={<Plantilla>  <Sellbuy/> </Plantilla> } />
                                          <Route path="/exchange" element={<Plantilla>  <Exchange/> </Plantilla> } />
                                          <Route path="/About" element={<Plantilla>  <About/> </Plantilla> } />

                                          <Route path="/exchange/:from/:to" element={<Plantilla>  <About/> </Plantilla> } />
                                          <Route path="/exchange/process/:transaction" element={<Plantilla>  <About/> </Plantilla> } />
                                          

                                          {/*authentication */}

                                          <Route path="/Login" element={<Plantilla>  <Login/> </Plantilla> } />
                                          <Route path="/register" element={<Plantilla>  <Register/> </Plantilla>}/>
                                          <Route path="/loss" element={<Plantilla>  <Loss/> </Plantilla>}/>

                                          {/* rutas protegida */}

                                          <Route path="/profile" element={<ProtectedRoute>  <Main/> </ProtectedRoute> } />
                                          <Route path="/identity" element={<ProtectedRoute>  <Main/> </ProtectedRoute> } />
                                          <Route path="/transacciones" element={<ProtectedRoute>  <Main/> </ProtectedRoute> } />
                                          <Route path="/password" element={<ProtectedRoute>  <Main/> </ProtectedRoute> } />
                                          <Route path="/2fa" element={<ProtectedRoute>  <Main/> </ProtectedRoute> } />
                                          

                                          <Route path="/buy_cripto/:from/:to" element={<ProtectedRoute>  <Main/> </ProtectedRoute> } />
                                          <Route path="/buy_cripto/process/:transaction" element={<ProtectedRoute>  <Main/> </ProtectedRoute> } />
                              
                              </Routes>
                  </BrowserRouter>
            </Authcontext.Provider>
  );
}

export default App;
