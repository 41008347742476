import React from 'react'
import { useTranslation } from 'react-i18next';
import logo from './../assets/images/icon2.webp';

const Cuerpo    = () => {
    const [t, i18n]      = useTranslation("global");
  return (
    <div>

            <div className='mejor_cripto'>
                    
               <div className='row'>

                   <div className='col-md-8 col-12'>
                                       

                                            <div className='titulo'>


                                                    <div className="text_main">{t('yourmayor')}</div>
                                                    <div className="text_main"> {t('cryptochange')}</div>
                                                    <div className="line_main"></div>


                                            </div>


                                            <div className='leyenda'>


                                                                <div className="row">

                                                                          <div className='col-md-6 col-12 mb-4'>
                                                                                    <div className="d-flex">
                                                                                            <div className="flex-shrink-0">
                                                                                                <div className="number_l">1</div>
                                                                                            </div>
                                                                                            <div className="flex-grow-1 ms-3">
                                                                                                <h6>{t('choose_tit1')} </h6>
                                                                                                    <span className="text_min">
                                                                                                    {t('chosse_text1')}
                                                                                                </span>
                                                                                            </div>
                                                                                    </div>
                                                                          </div>

                                                                          <div className='col-md-6 col-12 mb-4'>
                                                                            <div className="d-flex">
                                                                                    <div className="flex-shrink-0">
                                                                                        <div className="number_l">2</div>
                                                                                    </div>
                                                                                    <div className="flex-grow-1 ms-3">
                                                                                    <h6>{t('choose_tit2')} </h6>
                                                                                        <span className="text_min">{t('chosse_text2')}
                                                                                        </span>
                                                                                    </div>
                                                                            </div>

                                                                        </div>


                                                                        <div className='col-md-6 col-12 '>
                                                                            <div className="d-flex">
                                                                                    <div className="flex-shrink-0">
                                                                                        <div className="number_l">3</div>
                                                                                    </div>
                                                                                    <div className="flex-grow-1 ms-3">
                                                                                        <h6 >{t('chosse_tit3')}</h6>
                                                                                        <span className="text_min"> {t('chosse_text3')}
                                                                                        </span>
                                                                                    </div>
                                                                            </div>
                                                                    </div>


                                                                    
                                                                </div>
                                            </div>
                                            



                         {/*

                                <div className='leyenda'>


                                <div className="row">
                                        <div className='col-md-6 col-12 mb-4'>
                                                <div className="d-flex">
                                                            <div className="flex-shrink-0">
                                                                <div className="number_l">1</div>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <h6>{t('choose_tit1')} </h6>
                                                                    <span className="text_min">
                                                                    {t('chosse_text1')}
                                                                </span>
                                                            </div>
                                                    </div>
                                                </div>

                                                    <div className='col-md-6 col-12 mb-4'>
                                                        <div className="d-flex">
                                                                <div className="flex-shrink-0">
                                                                    <div className="number_l">2</div>
                                                                </div>
                                                                <div className="flex-grow-1 ms-3">
                                                                <h6>{t('choose_tit2')} </h6>
                                                                    <span className="text_min">{t('chosse_text2')}
                                                                    </span>
                                                                </div>
                                                        </div>

                                                    </div>

                                                    <div className='col-md-6 col-12 '>
                                                            <div className="d-flex">
                                                                    <div className="flex-shrink-0">
                                                                        <div className="number_l">3</div>
                                                                    </div>
                                                                    <div className="flex-grow-1 ms-3">
                                                                        <h6 >{t('chosse_tit3')}</h6>
                                                                        <span className="text_min"> {t('chosse_text3')}
                                                                        </span>
                                                                    </div>
                                                            </div>
                                                    </div>
                                    </div>

                                </div>
                                </div>
                                </div>
</div>
                             */}
                                                        
                    </div>

                    <div class="col-md-4 col-12">
                               <img src={logo}  className='img-fluid'/>
                    </div>
             </div>
    </div>


                <style>

                {`  
                          .mejor_cripto{

                              background: rgba(203,236,218,0.2); 
                              border-radius:8px;
                              padding:50px;
                              padding-bottom:100px;
                              padding-top:120px;
                          }

                          .mejor_cripto h6{
                             margin-bottom:2px;
                             padding-bottom:2px;
                          }

                          .ms-3 h6{
                            font-weight:bold;
                          }

                          .leyenda{
                               margin-top:25px;
                          }

                          .number_l{
                               line-height:0.6;
                               color:rgba(203,236,218,1);
                               font-size:86px;
                               font-weight:bold;
                               padding:0 !important;
                          }
                               .text_min{
                                  margin-top:-30px;
                                  font-size:10px;
                                  line-height:0;
                                  text-align:justify;
                                   
                               }
                              

                              @media only screen and (max-width : 900px) {

                                        .mejor_cripto{
                                            padding:20px;
                                        }
                                            .leyenda .d-flex{
                                                margin-top:40px;
                                                margin-bottom:10px;
                                            }

                                    
    
                            }
                     `}  

                        
                

                </style>

    </div>
  )
}

export default Cuerpo