import React,{useState} from 'react'
import {color} from "../../common/theme";
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import logo from '../../assets/images/logo.png';

function DefaultPage(props) {
  
  const [t, i18n]      = useTranslation("global");
  const [lng, setleng] = useState("en");

  function sele_lenguage(idioma){
    
      console.log("idioma es : "+idioma);
        i18n.changeLanguage(idioma)
         setleng(idioma); 
  }
   
  return (  <>
      <style>
        {`
                    header{
                        background:${color.BG_HEADER};
                        color:#161616;
                    }

                     .comple{
                                   background:url('./images/fondo_right.png');
                                   background-size:cover;
                                   
                      }


                     header a{
                            text-decoration:none;
                             color:#161616;
                     }
                             .logo_p{
                                   width:170px;
                             }
        `}
               
       </style>


    <header >
                 
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container-fluid">
              <Link to="/" className="navbar-brand" > <img src={logo} className="img-fluid logo_p"  alt="Iconbx"/> </Link>

              <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
              </button>

             {/* <Link to="/" className="navbar-toggler" > User</Link> */}



              <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
                 
                {/*  <li className="nav-item">  <Link to="/howitswork" className="nav-link" >{t("howitsworks")} </Link> </li>
                  <li className="nav-item">  <Link to="/fee" className="nav-link" >{t("fee")} </Link>  </li> */}
                    <li className="nav-item">  <Link to="/exchange" className="nav-link" >{t("exchange")} </Link></li>
                    <li className="nav-item">  <Link to="/sellbuy" className="nav-link" >{t("sellbuy")}     </Link></li>
                    <li className="nav-item">  <Link to="/about" className="nav-link" >{t("about")}         </Link></li>
                </ul>

                <form className="d-flex">
                     

                     
                          <div className="nav-item dropdown lng">
                              <Link to=""className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                {lng}
                              </Link>

                              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li><a onClick={() => sele_lenguage('es')} className="dropdown-item" href="#" >{t('spain')}</a></li>
                                <li><a onClick={() => sele_lenguage('en')}  className="dropdown-item" href="#">{t('english')}</a></li>
                                  {/*  <li><hr className="dropdown-divider"/></li>
                                    <li><Link to=""className="dropdown-item" href="#">Something else here</Link></li> */}
                              </ul>
                            </div>

                     

                    <Link to="/login"  type="button" className="btn btn-green me-2">{t("login")}</Link> 
                    <Link to="/register" type="button" className="btn btn-orange">{t("signup")}</Link>  
              
                </form>

              </div>

            </div>
      </nav>

    </header> 
                        {props.children}
       
      <footer> 
      </footer>
      <style>
                   {`
                                 .lng{
                                     margin-top:7px;
                                     margin-right:10px;
                                 }

                                 .btn-green{
                                            background: ${color.GREEN_DARK} !important;
                                            cursor: pointer;
                                 }

                                 .btn-orange{
                                       background: ${color.ORANGE} !important;
                                   
                                  }

                      `} 
      </style>

       </>

 
      
  )
}




export default  DefaultPage;


