import React, { useEffect, useState } from 'react';
import {Navigate} from 'react-router-dom';
import DefaultPage from "./layouts/DefaultPage";
import Login from './auth/login';
/*import ResponsiveDrawer from '../components/ResponsiveDrawer';
import CircularLoading from "../components/CircularLoading"; */


function Plantilla({children}) {

     const [auth, setAuth] = useState(true);

    return(   
            <DefaultPage children = {children}/> 
    )

    /*const auth = useSelector(state => state.auth);

    const [checkedAuth,setCheckedAuth] = useState(false);

    useEffect(()=>{
        if(auth.profile && auth.profile.uid){
            setCheckedAuth(true);
        }
        if(auth.error && auth.error.msg && !auth.profile){
            setCheckedAuth(true);
        }
    },[auth.profile,auth.error])

    return(
        checkedAuth?
            auth.profile && auth.profile.uid?
                matchUser(permit,auth.profile.usertype) ? <ResponsiveDrawer>{children}</ResponsiveDrawer>:<Navigate to="/login" />
            :<Navigate to="/login" /> 
        :<CircularLoading/>
    )
        */
}

export default Plantilla;