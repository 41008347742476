import axios from 'axios';
import React,  {useState, useContext, useEffect }  from 'react'
//buscar la forma de obtener el token

let token ="";

const cabe = ()=>{
  let header = "";
    if(token){
      header = {  headers: {
                      'Accept'      :    "application/Json",
                      'Authorization':   `Bearer ${token}`,
                      }
                }
    }
    return header;
}

export const posts = async(ruta, array)=>{
      let resp = "Server failed";
      try{  
            resp = await axios.post(ruta, array, cabe());
            resp = resp.data;
      }catch(error){
            resp += error;
      }
     return  resp;
}

export const gets = async (ruta)=>{
  let resp = "Server failed";
    try{    
      resp = await axios.get(ruta, cabe());
      resp = resp.data;
    }catch(error){
          resp += error;
    }
      return  resp;
}


