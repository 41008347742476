import React from 'react';
import {Form_exchange } from './../complements';
import './../main.css';
import img1 from './../assets/images/icon1.webp';
import {useTranslation} from 'react-i18next';
import Publi from './../complements/ini_publi';
import Mejor from './../complements/mejor_cripto';
import Servicio from './../complements/servicio';
import Cambia from './../complements/cambia_cripto';
import Limite from './../complements/limite_cripto';
import Beneficio from './../complements/beneficio';
import Compra from './../complements/compra_cripto';
import Footer from './../complements/footer';

const Main = () => {
  
  const [t, i18n] = useTranslation("global");

  return (
    <div>
       <main className="container p-1 " >
             <section className="banner inpar">
                            <div className="row">

                                    <div className='col-md-6 col-12 head_left'>

                                                <div className="titulo_main">
                                                       <div className="text_main">{t("how")}</div>
                                                       <div className="text_main">{t("itwork")}</div>
                                                       <div className="text_main ">COINSBX   <div className="line_main"></div></div>
                                                </div>


                                                <div className='text_resena'>
                                                       {t('main_text')} 
                                                </div>
                                              
                                    </div> 
                                   
                                    <div className='col-md-6 col-12 '>
                                                <img src={img1} className='img-fluid img_pr'/>
                                    </div>

                             </div>
             </section>
             {/*<section className="in_par details">
                        <div className="row">
                                 
                               <div className='col-md-6 col-12 text-center info_one'>
                                              <h4>
                                                   {t('fee')}
                                              </h4>
                                              <p>{t('ade_fee')}</p>

                                   </div>

                                   <div className='col-md-6 col-12 text-center info_one'>
                                              <h4>
                                                   {t('faq')}
                                              </h4>

                                              <p>{t('ade_faq')} <a href="#">Here</a></p>

                                   </div>

                        </div>
             </section> */}
            </main>
          
           <main className="container p-1">
           <Mejor/>
           <Cambia/>
           <Limite/>
           <Compra/>
           <Servicio/>
        
          {/*<Beneficio/> */}
          
          <Footer/>

           {/*
         
        
           */}
             
             </main>

             <style>

                            {`   
                                 .img_pr{
                                 width:90%;
                                 margin-top:-50px;
                                 }
                                  
                            `}

                            </style>  

    </div>

    
  )
}

export default Main