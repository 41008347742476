import React, { useState } from "react"
import {color} from "./../../common/theme";
import axios  from "axios";
import {Navigate, Link } from 'react-router-dom';
import './../../assets/css/auth.css'
import Completa from "../../complements/comple_signup";
import Solicita from "../../complements/solicitud_signup";
/*import { useAuth } from '../../Auth/AuthProvider'; */
import {useTranslation} from "react-i18next"; 

function Register(){

    const [t, i18n]                                 = useTranslation("global");
    const [sendcode, setSendcode]                   = useState(false);

        
    return   <>
        
                     <div className="content-fluid">
                                

                     <div className="row justify-content-center">

                           <div className="col-md-6 col-12">

                                {(sendcode)? <Completa />: <Solicita/> }
                                        
                        </div>
                          
                          </div>
                   </div>

                   <style> 
                      {`
                                 .zona_login{
                                     margin-top:10%;
                                     background:${color.GREEN_AUTH}
                                 }

                      `} 
             </style>
               </>
  }

  export default Register;