import React, { useState} from 'react'
import { Link } from 'react-router-dom';
import { color } from './../../common/theme';
import './../../assets/css/auth.css'
import { useTranslation } from 'react-i18next';
//import { server } from '../../common/server';


function Login() {

       const [t, i18n] = useTranslation("global");
       const [username, setUsername] = useState('');
       const [pass, setPass]         = useState('');

       function confirmar(e){
            e.preventDefault();
            
            
       }


  return (  <>
            
                         <div className="content-fluid">

                         <div className="row justify-content-center">

                            <div className="col-md-6 col-12 ">
                                
                                <div className='zona_login '>
                                  
                                     <div className='titulo'>{t('signin')}</div>
                                     <form onSubmit={confirmar}>
                                         <div className="form">
                                           <label >{t('email')}</label>
                                           <input type="text"
                                             value={username}
                                             onChange={(e) => setUsername(e.target.value)}
                                             required
                                             id="username"
                                             className="form-control" />
                                         </div>

                                         <div className="form">
                                           <label >{t('pass')}</label>
                                           <input type="password"  className="form-control"
                                            value={pass}
                                            id="pass"
                                            onChange={(e) => setPass(e.target.value)}
                                            required
                                             />
                                         </div>

                                         <div className="form">
                                               <div className="d-grid gap-2">
                                                    <button className="btn btn_orange">{t('signin')}</button>
                                               </div>
                                         </div>

                                         <div className="footer">
                                         {t('noaccount')} <Link to="/register" className="">{t('signup')}</Link>

                                                <div className='forgot'>
                                                        <Link to="/loss"> {t('loss_account')} </Link>
                                                </div>
                                         </div>
                                      </form>
                                </div>
                               
                                </div>
                                </div>
                          </div>



                          <style> 
                      {`
                                 .zona_login{
                                      margin-top: 17%;
                                     background:${color.GREEN_AUTH}
                                 }

                      `} 
             </style>
            </>      
  )
}
export default Login;