export  const color = {
  
  
  GREEN_BOX: '#c8ecda',
  GREEN_FORM:    '#ade2c8',
  GREEN_LIGHT:'#ebf8f1',
  GREEN_LINE: '#89d5ad',
  ORANGE:'#ffb540',
  GREEN_DARK:'#0faa5d',
  GREEN_AUTH:'#c7ecda',
  INPUT_AUTH:'#ade2c8',
  GREEN:'#91d8b5',  
  BG_HEADER:'#ebf1ed',

  SKY: '#1E81D3',
  LIGHT_RED:"#FF2121",
  RE_GREEN:"#7ED957",
  GREY:"#C0C0C0",
  TRANSPARENT: "transparent",
  WHITE: "#ffffff",
  BLACK: "#161616",
  RED: "#DF2E38",
  YELLOW: "#FFDE59 ",
  FOOTERTOP: "#969696",

}