
import React, {useState }from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';


const Solicitud = () => {
     const [t, i18n]         = useTranslation("global");
     const [email, setEmail] = useState('');
  return (
    <>
                 
               <div className='zona_login '>
                                      
                                      <div className='titulo'>{t("register")}</div>
                                        <div className="form">
                                            <label >{t('email')}</label>
                                            <input type="text" name="" id=""
                                              value = {email}
                                              onChange={(e)=>{setEmail(e.target.value)}}
                                              className="form-control" />
                                        </div>
                                      
                                        <div className="form">
                                                <div className="d-grid gap-2">
                                                    <button className="btn btn_orange">{t('signup')}</button>
                                                </div>
                                        </div>
                                        
                                        <div className='footer'>{t('noaccount')} <Link to="/login">{t('signin')}!</Link> </div>
                 </div>

        
        </>
  )
}

export default Solicitud

