import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import App from './App';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import i18next from 'i18next';
import global_en   from './lenguaje/en.json'
import global_es   from './lenguaje/es.json'

i18next.use(initReactI18next).init({
             interpolation: { escapeValue: false },
             fallbackLng:'en',
             lng: 'en',
             resources:{
                 en: {global : global_en},
                 es: {global : global_es}
             }

})

//cammbar idioma  i18n.changeLanguage('es')

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
        <I18nextProvider>
             <App />
        </I18nextProvider>
  </React.StrictMode>
);


